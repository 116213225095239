import API from './API';
import Collection from './Collection';
import Storage from './Storage';
import createDatabase from './createDatabase';

const getDatabase = (id) => {
    const db = createDatabase(id);

    const models = {
        comments: new Collection(new API('/api/comments/'), new Storage(db, 'comments')),
        entities: new API('/api/entities/'),
        rips: new Collection(new API('/api/rips/'), new Storage(db, 'rips')),
        histories: new Collection(new API('/api/histories/')),
        simulations: new API('/api/simulations/'),
        users: new API('/api/users/'),
        contracts: new API('/api/contracts/'),
        signatures: new API('/api/signatures/'),
        ripsWithoutDocumentCifPendingValidations: new API('/api/rips/ripsWithoutDocumentCifPendingValidations'),
        documentsCifPendingValidations: new API('/api/rips/documentsCifPendingValidations'),
        contractsPendingValidations: new API('/api/contracts/pendingValidations'),
        invoices: new API('/api/payment/invoices'),
        payouts: new API('/api/payment/payouts'),
        syncLogs: new API('/api/syncLogs'),
    };

    const collections = [
        models.comments,
        models.rips,
    ];

    const syncUpAll = () => Promise.all(collections.map((collection) => collection.syncChanges()));

    const clearDb = () => {
        const clearPromises = [db.syncs.clear()];

        collections.forEach((collection) => {
            clearPromises.push(collection.storage.items.clear());
            clearPromises.push(collection.storage.changes.clear());
        });

        return Promise.all(clearPromises);
    };

    return { ...models, syncUpAll, clearDb };
};

export default getDatabase;
