import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Icon from 'common/components/Icon';

import './PublicLayout.scss';
import { withSession } from './sessions';
import Spinner from './Spinner';
import { GET } from '../utils/httpMethods';

const traceAmplitude = async () => {
    const { activerAmplitude } = await GET('/api/amplitude-activation').then((response) => response.json());
    if (activerAmplitude && 'true' === activerAmplitude) {
        let urlATracer = '/';

        if (window.location.pathname.includes('/rip/')) {
            const array = window.location.pathname.split('/');
            const arraySimplifiee = array.slice(3);
            urlATracer = `/${arraySimplifiee.join('/')}`;
        } else {
            urlATracer = window.location.pathname;
        }

        fetch('/api/trace', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ urlATracer }),
        });
    }
};

const PublicLayout = ({ children, className, floatingContent, buttonText, buttonProps, session = {}, ...props }) => {
    useEffect(() => {
        if (!session.instanceSettings || !session.instanceSettings.logo) {
            session.syncInstanceSettings();

            traceAmplitude();
        }
    }, []);

    if (!session.instanceSettings) {
        return <Spinner />;
    }
    const { label, logo } = session.instanceSettings;

    return (
        <div className={classNames('public-container', className)} {...props}>
            <div className="public-inner">
                {logo && label && <img src={logo} alt={`Logo ${label}`} />}
                {children}
                <div className="floating-content">{floatingContent}</div>
                <p className="public-layout__version">v5.7.2</p>
                {buttonText && (
                    <button className="floating-button" {...buttonProps}>
                        {buttonText}
                        <Icon icon="angle-right" />
                    </button>
                )}
            </div>
        </div>
    );
};

PublicLayout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    floatingContent: PropTypes.node,
    buttonText: PropTypes.string,
    buttonProps: PropTypes.shape({}),
    session: PropTypes.shape({}),
};

PublicLayout.defaultProps = {
    className: null,
    floatingContent: null,
    buttonText: null,
    buttonProps: null,
};

export default withSession(PublicLayout);
