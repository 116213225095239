import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import dataForm from '../dataForm';
import Title from '../../Title';
import './Form.css';
import Footer from '../Footer';
import fullName from '../../../common/utils/fullName';
import hasPartner, { shouldPartnerSign } from '../../../common/utils/hasPartner';
import { useSession } from '../../sessions';
import { POST } from '../../../utils/httpMethods';

const Form = ({ rip, id, clientSigner = null, partnerSigner = null, isElectronicSign }) => {
    const { idBoxShoyo, partnerIdBoxShoyo, clientAndPartnerSignatureDate } = rip;

    const statusSignatureClient = clientAndPartnerSignatureDate && clientAndPartnerSignatureDate.client ? clientAndPartnerSignatureDate.client.type : '';
    const statusSignaturePartner = clientAndPartnerSignatureDate && clientAndPartnerSignatureDate.partner ? clientAndPartnerSignatureDate.partner.type : '';

    const session = useSession();
    const { shoyoToken } = session.shoyo;

    const signers = { clientSigner, partnerSigner };

    const creationBoxShoyo = async () => {
        const isPartnerExist = (shouldPartnerSign(rip) && 'universign' !== partnerSigner);
        await POST(`/api/contracts/${id}/shoyo/box/create`, {
            body: {
                partner: isPartnerExist,
                session,
                signers,
            },
        });
    };

    const updateBoxShoyo = async () => {
        await POST(`/api/contracts/${id}/shoyo/box/update`, {
            body: {
                idBoxShoyo,
                partnerIdBoxShoyo,
                shoyoToken,
                session,
                signers,
            },
        });
    };

    useEffect(() => {
        if (idBoxShoyo) {
            updateBoxShoyo();
        } else {
            creationBoxShoyo();
        }
    }, []);

    const renderManualSign = () => (
        <div className="render-manual-sign">
            <Title className="title">
                Signature manuscrite enregistrée.
            </Title>

        </div>
    );

    const dualElectronicSign = 'universign' === clientSigner && 'universign' === partnerSigner;
    const renderElectronicSign = () => (
        <div className="render-electronic-sign">
            <Title className="title">
                Une transaction a été ouverte auprès d’universign.
            </Title>
            <div className="text-content">
                {dualElectronicSign ? <p>Un mail va être envoyé aux signataires dans l’ordre suivant :</p> : <p> Un mail va être envoyé au signataire :</p>}

                <ul>
                    {'universign' === clientSigner && ('refusal' !== statusSignatureClient) ? <li>{fullName(rip)}</li> : null}
                    {(hasPartner(rip.familySituation) && 'universign' === partnerSigner && ('refusal' !== statusSignaturePartner)) ? (
                        <li>
                            {fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })}
                        </li>
                    ) : null}
                </ul>

                Le lien généré est valable 14 jours.
                {dualElectronicSign && (
                    <p>
                        Pour information, tant que le signataire précédent n’a pas effectué sa signature, le signataire suivant ne reçoit pas le mail lui permettant de signer à son tour.
                    </p>
                )}
            </div>
        </div>
    );

    const renderBoxState = () => (
        <div className="render-box-state">
            <Title className="title">
                Un coffre-fort sécurisé eDoc est en cours de création pour :
            </Title>
            <div className="text-content">
                <ul>
                    { ('refusal' !== statusSignatureClient) && <li> {fullName(rip)}</li>}
                    {rip.partnerId && shouldPartnerSign(rip) && ('refusal' !== statusSignaturePartner) && (
                        <li> {fullName({
                            firstName: rip.partnerFirstName,
                            lastName: rip.partnerLastName,
                        })}
                        </li>
                    )}
                </ul>
                <p>
                    Vous pourrez y retrouver le Relevé d&apos;Informations Patrimoniales dans quelques secondes.
                </p>
            </div>

        </div>
    );

    return (
        <Fragment>

            <div className="signature-state-container">
                {('universign' !== clientSigner || (shouldPartnerSign(rip) && 'universign' !== partnerSigner)) &&
                    renderManualSign()
                }
                {isElectronicSign && (
                    renderElectronicSign()
                )}
                { renderBoxState()}
                <Footer
                    back={`/rip/${id}/profil-client`}
                    nextRedirectTo="/"
                    nextButtonContent="Retour vers la liste des rips"
                />
            </div>

        </Fragment>

    );
};
Form.propTypes = {
    id: PropTypes.string,
    clientSigner: PropTypes.string,
    partnerSigner: PropTypes.string,
    rip: PropTypes.shape({
        signature: PropTypes.string,
        partnerSignature: PropTypes.string,
        universignTransaction: PropTypes.string,
        partnerId: PropTypes.string,
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
        familySituation: PropTypes.string,
        idBoxShoyo: PropTypes.string,
        partnerIdBoxShoyo: PropTypes.string,
        clientAndPartnerSignatureDate: PropTypes.shape({
            client: PropTypes.shape({
                type: PropTypes.string,
            }),
            partner: PropTypes.shape({
                type: PropTypes.string,
            }),
        }),
    }),
    isElectronicSign: PropTypes.bool,
};

export default compose(dataForm({
    form: 'rip-appointment',
    id: ({ id }) => id,
    type: 'rip',
}))(Form);
