const modes = {
    user: {
        label: 'Vos RIPs',
        icon: 'userN',
        isAvailable: () => true,
    },
    all: {
        label: 'Tous les RIPs',
        icon: 'users',
        isAvailable: ({ permissions }) => permissions.isCaseManager(),
    },
    allForManager: {
        label: 'Tous les RIPs',
        icon: 'users',
        isAvailable: ({ permissions }) => permissions.isManager() && !permissions.isAdmin() && !permissions.isCaseManager(),
    },
    outdated: {
        label: 'RIPs à actualiser',
        icon: 'exclamation',
        isAvailable: () => true,
    },
    archives: {
        label: 'Archivés',
        icon: 'archive',
        isAvailable: () => true,
    },
};

export default modes;
