import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Action } from '../list';

import './FieldInfo.css';

const FieldInfoPrisme = ({ children, id, buttonIsHidden }) => (
    <OverlayTrigger
        trigger={['hover', 'click']}
        overlay={<Popover className="form-field-info-popover" placement="right" id={id}>{children}</Popover>}
        rootClose
    >
        <span>
            <Action
                className="rip-list-state-button deactivated"
                icon="upload"
                text="export"
                nonVisible={buttonIsHidden}
            />
        </span>
    </OverlayTrigger>
);

FieldInfoPrisme.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    buttonIsHidden: PropTypes.bool,
};

export default FieldInfoPrisme;
