import { get, filter, isEmpty, size, set } from 'lodash/fp';
import moment from 'moment';
import validate, {
    checkDateDependencies,
    checkIsDate,
    checkRequiredField,
    checkRequiredIntegerField,
    checkRequiredOwnerField,
    branchValidate,
    switchValidate,
    validateCollection,
} from '../lib';

const notOwner = (situation) => situation && 'owner' !== situation;

const validateMainResidenceAcquisitionYear = (field) =>
    checkDateDependencies(
        'mainResidenceAcquisitionYear',
        field,
        "L'année d'acquisition ne peut être antérieure à l'année de naissance",
        { buildDate: (date) => moment(date, 'YYYY').endOf('year') },
    );

const validateMortgages = (name) => [
    branchValidate((errors, values) => 'closed' !== get(`${name}.type`, values), [
        checkRequiredIntegerField(`${name}.mortgageType`),
        checkRequiredIntegerField(`${name}.initialLoanAmount`),
        checkRequiredIntegerField(`${name}.dueDateAmount`),
    ]),
];

const validateUniquenessClosedMortgage = (name) => (errors, values) => {
    const errorString = 'Un seul crédit soldé autorisé.';
    const closedMortgages = filter(({ type }) => 'closed' === type, get(name, values));
    const errorField = `${name}._error`;

    return 1 >= size(closedMortgages) ? errors : set(errorField, errorString, errors);
};

const validateProperties = (name) => [
    // common validators
    checkRequiredOwnerField(`${name}.owner`, { showSci: true }),
    checkRequiredField(`${name}.estimatedValue`),
    branchValidate((errors, values) => 'secondHome' !== get(`${name}.type`, values), [
        checkRequiredIntegerField(`${name}.rentalIncome`),
    ]),
    branchValidate((erros, values) => ['ScellierIntermediaire', 'Pinel']
        .includes(get(`${name}.fiscalFramework`, values)), [
        checkRequiredField(`${name}.prorogatedReduction`),
        branchValidate((errors, values) => get(`${name}.prorogatedReduction`, values), [
            checkRequiredField(`${name}.prorogationDetaxAmount`),
            checkRequiredField(`${name}.prorogationDetaxEndDate`),
        ]),
    ]),
    // we have some extra validators for the property of type "other"
    branchValidate((errors, values) => 'other' === get(`${name}.type`, values), [
        checkRequiredField(`${name}.description`),
    ]),
    // some fields are required only if there's mortgages
    branchValidate((errors, values) => !isEmpty(get(`${name}.mortgages`, values)), [
        checkRequiredField(`${name}.possessionType`),
        checkRequiredField(`${name}.acquisitionYear`),
        checkRequiredField(`${name}.acquisitionMethod`),
        // some fields are specific to an acquisition method
        branchValidate((errors, values) => 'purchase' === get(`${name}.acquisitionMethod`, values), [
            checkRequiredIntegerField(`${name}.acquisitionPrice`),
        ]),
    ]),
    // validate mortgages
    validateCollection(`${name}.mortgages`, validateMortgages),
    validateUniquenessClosedMortgage(`${name}.mortgages`),
];

const validateScis = (name) => [
    checkRequiredField(`${name}.sciName`),

    // Other owner
    branchValidate((errors, values) => 'other' === get(`${name}.owner`, values), [
        validateCollection(`${name}.otherOwnerWithShare`, (prefix) => [
            checkRequiredField(`${prefix}.name`),
            checkRequiredIntegerField(`${prefix}.share`),
        ]),
    ]),
    branchValidate((errors, values) => 'clientAndPartner' === get(`${name}.owner`, values), [
        checkRequiredIntegerField(`${name}.yourShare`),
        checkRequiredIntegerField(`${name}.partnerShare`),
    ]),
    branchValidate((errors, values) => 'partner' === get(`${name}.owner`, values), [
        checkRequiredIntegerField(`${name}.partnerShare`),
    ]),
    branchValidate(
        (errors, values) => !['partner', 'other', 'clientAndPartner'].includes(get(`${name}.owner`, values)),
        [checkRequiredIntegerField(`${name}.yourShare`)],
    ),
];

const validateCharges = (name, element) => {
    const cohabitationValidator = (options) => branchValidate(
        (errors, { familySituation }) => 'cohabitation' === familySituation,
        [checkRequiredOwnerField(`${name}.fiscalHome`, options)],
    );

    const loanValidators = [
        cohabitationValidator(),
        checkRequiredIntegerField(`${name}.monthlyPayment`),
        checkRequiredField(`${name}.start`),
        checkIsDate(`${name}.start`),
        checkRequiredIntegerField(`${name}.duration`),
    ];

    switch (get('type', element)) {
        case 'carLoan':
            switch (get('carOption', element)) {
                case 'LOA':
                case 'LLD':
                    return [
                        cohabitationValidator(),
                        checkRequiredIntegerField(`${name}.rent`),
                        checkRequiredField(`${name}.start`),
                        checkIsDate(`${name}.start`),
                        checkRequiredIntegerField(`${name}.duration`),
                    ];
                case 'loan':
                    return loanValidators;
                default:
                    return [checkRequiredField('carOption')];
            }
        case 'consumerLoan':
            return loanValidators;
        case 'pension':
            return [
                cohabitationValidator(),
                checkRequiredIntegerField(`${name}.rent`),
            ];
        case 'other':
            switch (get('otherOption', element)) {
                case 'loan':
                    return loanValidators;
                case 'other':
                    return [
                        cohabitationValidator({ showSci: true }),
                        checkRequiredField(`${name}.subtype`),
                        checkRequiredIntegerField(`${name}.monthlyPayment`),
                    ];
                default:
                    return [
                        cohabitationValidator({ showSci: true }),
                        checkRequiredField('otherOption'),
                    ];
            }
        default:
            return [];
    }
};

export default validate([
    checkRequiredField('mainResidence'),
    switchValidate(get('mainResidence'), {
        owner: [
            checkRequiredOwnerField('mainResidenceTenant', { showSci: true }),
            branchValidate((errors, values) => 'closed' !== get('mainResidenceMortgage', values), [
                checkRequiredField('mainResidencePossessionType'),
                checkRequiredField('mainResidenceAcquisitionYear'),
                branchValidate((_, values) => 'client' === get('mainResidenceTenant', values), [
                    validateMainResidenceAcquisitionYear('birthDate'),
                ]),
                branchValidate((_, values) => 'partner' === get('mainResidenceTenant', values), [
                    validateMainResidenceAcquisitionYear('partnerBirthDate'),
                ]),
                branchValidate((_, values) => 'clientAndPartner' === get('mainResidenceTenant', values), [
                    validateMainResidenceAcquisitionYear('birthDate'),
                    validateMainResidenceAcquisitionYear('partnerBirthDate'),
                ]),
                checkRequiredField('mainResidenceAcquisitionMethod'),
                checkRequiredIntegerField('mainResidenceEstimatedValue'),
                branchValidate((errors, values) => 'purchase' === get('mainResidenceAcquisitionMethod', values), [
                    checkRequiredIntegerField('mainResidenceAcquisitionPrice'),
                ]),
            ]),
            validateCollection('mainResidenceMortgages', validateMortgages),
            validateUniquenessClosedMortgage('mainResidenceMortgages'),
        ],
        tenant: [checkRequiredOwnerField('mainResidenceTenant'), checkRequiredField('mainResidenceRent')],
    }),
    // validate mortgages
    branchValidate((errors, values) => notOwner(get('mainResidence', values)), [
        checkRequiredField('futureOwner'),
        branchValidate((errors, values) => get('futureOwner', values), [
            checkRequiredField('futureOwnerDate'),
            checkIsDate('futureOwnerDate'),
            checkDateDependencies(
                'futureOwnerDate',
                'createdAt',
                'La date doit être postérieure à la date de création du RIP',
            ),
        ]),
    ]),
    validateCollection('sci', validateScis),
    validateCollection('property', validateProperties),
    checkRequiredField('haveCharges'),
    branchValidate((errors, { haveCharges }) => haveCharges, [checkRequiredField('charges', true)]),
    validateCollection('charges', validateCharges),
]);
