import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import GridRadioGroup from './GridRadioGroup';
import GridRadioButton from './GridRadioButton';
import Error from '../Error';

const renderError = (error, errortext = 'Veuillez renseigner ce champ.') => (
    error ? <Error errortext={errortext} forceerror /> : <p style={{ display: 'none' }} />
);

const GridRadioChoices = ({ choices, name, required, leftComponent, errortext, disabled, isCa, ...props }) => {
    const [desactive, setDesactive] = useState(false);
    const [isInputError, setIsInputError] = useState(false);

    useEffect(() => {
        if (false === navigator.onLine) {
            return setDesactive(true);
        }

        return setDesactive(false);
    }, [navigator.onLine]);

    useEffect(() => {
        setIsInputError(false);
    }, [name]);

    const verifieSiChoixDifferentsDesRipsUtilisateurs = (choix) => 'Tous les RIPs' === choix || 'RIPs à actualiser' === choix || 'Archivés' === choix;

    const doitEtreDesactive = (valeurChoix) => {
        if (verifieSiChoixDifferentsDesRipsUtilisateurs(valeurChoix) && true === desactive) {
            return true;
        }

        return false;
    };

    const disabledMMA = (valeurChoix) => {
        if (!isCa && 'MMA' === valeurChoix) {
            return true;
        }

        return false;
    };

    return (
        <GridRadioGroup name={name} {...props}>
            {Object.entries(choices).map(([key, value]) => (
                <GridRadioButton
                    required={required}
                    value={key}
                    label={value}
                    key={key}
                    leftComponent={leftComponent}
                    // TODO disabledMMA à enlever en septembre
                    disabled={disabledMMA(value) || doitEtreDesactive(value) || disabled}
                    setIsInputError={setIsInputError}
                    {...props}
                />
            ))}
            { renderError(isInputError, errortext)}

        </GridRadioGroup>
    );
};

GridRadioChoices.propTypes = {
    choices: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    leftComponent: PropTypes.func,
    errortext: PropTypes.string,
    isCa: PropTypes.bool,
};

GridRadioChoices.defaultProps = {
    required: false,
    // explictly use undefined to allow the default value on GridRadioButton to be used instead
    leftComponent: undefined,
};

export default GridRadioChoices;
