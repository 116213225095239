import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import hasPartner from 'common/utils/hasPartner';
import Title from 'components/Title';
import FormGroup from 'components/form/Group';
import Card from './InvestorProfileCard';

import './InvestorProfile.css';

const InvestorProfile = ({
    familySituation,
    firstName,
    lastName,
    partnerFirstName,
    partnerLastName,
    investorProfiles,
}) => {
    const withPartner = hasPartner(familySituation);
    const clientData = get('client', investorProfiles);
    const partnerData = get('partner', investorProfiles);

    return (
        <FormGroup>
            <Title>Définissez votre profil investisseur à l&apos;aide du questionnaire de risque*</Title>
            <div className="investor-profile-cards">
                <Card
                    firstName={firstName}
                    lastName={lastName}
                    target="client"
                    data={clientData}
                    fieldName="allowChangingInvestorProfile"
                    withTitle={withPartner}
                />
                {withPartner && (
                    <Card
                        firstName={partnerFirstName}
                        lastName={partnerLastName}
                        target="partner"
                        data={partnerData}
                        fieldName="partnerAllowChangingInvestorProfile"
                        withTitle
                    />
                )}
            </div>
            <p className="help-block">
                L&apos;ensemble des questions et vos réponses sont tenues à votre disposition par
                l&apos;intermédiaire de votre conseiller.
            </p>
        </FormGroup>
    );
};

InvestorProfile.propTypes = {
    familySituation: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    partnerLastName: PropTypes.string,
    partnerFirstName: PropTypes.string,
    investorProfiles: PropTypes.shape({}),
};

InvestorProfile.defaultProps = {
    familySituation: null,
    partnerFirstName: null,
    investorProfiles: {},
};

export default formValues({
    firstName: 'firstName',
    lastName: 'lastName',
    partnerLastName: 'partnerLastName',
    partnerFirstName: 'partnerFirstName',
    familySituation: 'familySituation',
    investorProfiles: 'investorProfiles',
})(InvestorProfile);
