import { flowRight } from 'lodash/fp';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lifecycle, branch, renderNothing } from 'recompose';
import { formValues, reduxForm } from 'redux-form';

import { filterRecommendations } from 'common/utils/recommendations';
import validateIncomesForReco from 'common/formValidation/rip/taxesApprovalCifValidation';
import withRouter from '../../withRouter';
import alertEmail from '../../../utils/alertEmail';
import { getIsValidator } from '../../../actions';
import dataProvider from '../../dataProvider';
import RipForm from '../Form';
import { POST } from '../../../utils/httpMethods';
import Download from '../Download';
import Comment from './Comment';
import Recommendations from './Recommendations';
import { withSession } from '../../sessions';
import ChoiceResponse from './ChoiceResponse';
import EnveloppeResponse from './EnveloppeResponse';
import ChoiceContact from './ChoiceContact';
import RipTaxIncomes from './TaxIncomesListForRecommendations';
import ESigne from './Esigne';

const FormInner = ({ rip, session, isErrors, isApproved, ...props }) => {
    const { target } = props.params;
    const { instanceTheseis } = session;
    const [isValuesCompleted, setIsValuesCompleted] = useState(false);
    const [isGenerated, setIsGenerated] = useState(false);
    const recommendationCif = 'client' === target ? filterRecommendations(rip.recommendationsCIF) : filterRecommendations(rip.partner.recommendationsCIF);

    useEffect(() => {
        if ((null === isErrors || isErrors === undefined)) {
            setIsValuesCompleted(true);
        } else {
            setIsValuesCompleted(false);
        }
    }, [isErrors]);

    const isNotApproved = 'no' === isApproved;

    return (
        <RipForm {...props} id={rip.id} hideBackButton enveloppeESigneCreated={isNotApproved || isGenerated} nextButtonContent="Valider" >
            <Download rip={rip} isInstanceTheseis={instanceTheseis} />
            <Recommendations rip={rip} recommendationCif={recommendationCif} />
            <EnveloppeResponse />
            <ChoiceContact rip={rip} target={target} />
            <RipTaxIncomes ripFromRecommendation={rip} />
            <ChoiceResponse rip={rip} />
            {'yes' === isApproved && <ESigne rip={rip} isValuesCompleted={isValuesCompleted} setIsGenerated={setIsGenerated} />}
            <Comment />
            <p>Cliquez sur &quot;Valider&quot; pour terminer et notifier le conseiller. Cette fonction sera disponible uniquement apr&egrave;s g&eacute;n&eacute;ration et v&eacute;rification des donn&eacute;es du document de conseil CIF sur eSigne</p>
        </RipForm>
    );
};

FormInner.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            approval: PropTypes.string,
        }),
    }),
    rip: PropTypes.shape({
        id: PropTypes.string,
        recommendationsCIF: PropTypes.shape({}),
        partner: PropTypes.shape({
            recommendationsCIF: PropTypes.shape({}),
        }),
    }).isRequired,
    session: PropTypes.shape({
        instanceTheseis: PropTypes.bool,
    }).isRequired,
    params: PropTypes.shape({
        target: PropTypes.string,
    }),
    isErrors: PropTypes.shape({
    }).isRequired,
    isApproved: PropTypes.string,
};

const Form = flowRight([
    withRouter,
    withSession,
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
    reduxForm({
        form: 'cif-approval',
        validate: validateIncomesForReco,
        onSubmit: (body, dispatch, { rip: { id } }) => POST(`/api/rips/${id}/sendAdvisorCIFNotification`, { body }),
        onSubmitSuccess: alertEmail,
    }),
    connect(null, { load: getIsValidator }),
    lifecycle({
        componentDidMount() {
            this.props.load(this.props.rip.id);
        },
    }),
    formValues('isApproved'),
    connect((state) => ({ isValidator: state.isValidator, isErrors: state.form['cif-approval'].syncErrors })),
    branch(({ isValidator }) => !isValidator, renderNothing),
])(FormInner);

export default Form;
