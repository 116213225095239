import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose, pure } from 'recompose';

import { Layout } from 'components/layouts';
import { listProvider, HideActiveItemButton, withActiveItem } from '../../list';
import { withSession } from '../../sessions';
import { userShape } from './Item';
import { GET } from '../../../utils/httpMethods';
import Title from '../../Title';
import teamListTable from './TeamListTable';

import './index.css';

const TeamListInner = ({ itemsDesactive, itemsActive }) => {
    const [isInactiveWithRips, setIsInactiveWithRips] = useState([]);
    const [isInactiveWithoutRips, setIsInactiveWithoutRips] = useState([]);
    const [itemsActiveRips, setitemsActiveRips] = useState([]);

    useEffect(() => {
        (async () => {
            const handleUsers = async (ite) => {
                const results = await Promise.all(ite.map(async (user) => {
                    try {
                        const donnees = await GET(`/api/rips/user-rips/${user.id}/updatedAt/DESC`);
                        const ripsRecuperes = await donnees.json();

                        if (ripsRecuperes.count > 0) {
                            return user;
                        }

                        return null;
                    } catch (error) {
                        return null;
                    }
                }));

                const usersWithRips = results.filter(result => result !== null);

                return usersWithRips;
            };

            handleUsers(itemsDesactive)
                .then(usersWithRips => {
                    setIsInactiveWithRips(usersWithRips);
                    setitemsActiveRips(itemsActive);
                    if (usersWithRips) {
                        // On filtre sur les utilisateurs inactifs et on récupère ceux qui n'ont pas de rips
                        const result = itemsDesactive.filter(item =>
                            !isInactiveWithRips.some(inactiveItem => inactiveItem.id === item.id));
                        setIsInactiveWithoutRips(result);
                    }
                });
        })();
    }, [itemsDesactive]);

    return (
        <Layout className="team-list">
            <div className="container">

                {isInactiveWithRips ? (
                    <div className="title" style={{ borderBottom: '1px solid #28E3E9' }}>
                        <Title style={{ display: 'flex', flexDirection: 'row' }}><b>Utilisateurs inactifs, Rips à réaffecter</b></Title>
                    </div>
                ) : null }
                {isInactiveWithRips && teamListTable({ items: isInactiveWithRips, type: 'isInactiveWithRips', fonctionSet: { setIsInactiveWithRips } })}

                {itemsActiveRips.length > 0 ? (
                    <div className="title" style={{ borderBottom: '1px solid #28E3E9' }}>
                        <Title style={{ display: 'flex', flexDirection: 'row' }}><b>Utilisateurs actifs</b></Title>
                    </div>

                ) : null}
                {itemsActiveRips && teamListTable({ items: itemsActiveRips, type: 'activeItems', fonctionSet: { setitemsActiveRips } })}

                {isInactiveWithoutRips.length > 0 ? (
                    <div className="title" style={{ borderBottom: '1px solid #28E3E9' }}>
                        <Title style={{ display: 'flex', flexDirection: 'row' }}><b>Utilisateurs inactifs</b></Title>
                    </div>
                ) : null}
                {isInactiveWithoutRips && teamListTable({ items: isInactiveWithoutRips, type: 'isInactiveWithoutRips', fonctionSet: { setIsInactiveWithoutRips } })}

                <HideActiveItemButton />
            </div>

        </Layout>
    );
};
TeamListInner.propTypes = {
    itemsDesactive: PropTypes.arrayOf(PropTypes.shape(userShape).isRequired).isRequired,
    itemsActive: PropTypes.arrayOf(PropTypes.shape(userShape).isRequired).isRequired,
};

const TeamList = compose(
    withSession,
    listProvider({
        sorts: {
            lastName: { initialOrder: 'asc', default: true },
        },
        filter: ({ session }) => ({ entityId }) => session.managedIds.includes(entityId) || session.permissions.isAdmin(),
        name: 'user',
        type: 'user',
    }),
    withActiveItem,
    pure,
)(TeamListInner);

export default TeamList;
