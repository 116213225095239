import PropTypes from 'prop-types';
import React from 'react';
import { SubmissionError } from 'redux-form';
import { PATCH } from 'utils/httpMethods';
import { setModal } from 'actions';
import { Layout } from 'components/layouts';
import EditForm from './EditForm';
import userProvider from './userProvider';
import MiddleOfficeError from '../../MiddleOfficeError';

const handleSubmit = async ({ id, ...body }) => {
    try {
        const response = await PATCH(`/api/users/${id}`, { body });

        return response.json();
    } catch (error) {
        const data = await error.response.json();
        throw new SubmissionError({ _error: data.message });
    }
};
const resetPassword = (id) => () => PATCH(`/api/users/${id}/resetPassword`)
    .then(() => alert(`Mot de passe en cours de réinilitialisation,\n
                       consulter vos mails pour continuer la procédure.`))
    .catch(() => alert('Échec lors de la réinitialisation du mot de passe. Veuillez réessayer.'));

const Edit = ({ params: { id }, user, navigate, subscriptionInfos, syncSubscriptionInfos }) => (
    <Layout hasFooter>
        <EditForm
            resetPassword={resetPassword(id)}
            onSubmit={handleSubmit}
            onSubmitSuccess={() => navigate('/admin/user')}
            onSubmitFail={({ _error }, dispatch) => _error && dispatch(setModal(() => <MiddleOfficeError error={_error} />))}
            initialValues={user}
            subscriptionInfos={subscriptionInfos}
            syncSubscriptionInfos={syncSubscriptionInfos}
        />
    </Layout>
);

Edit.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({}),
    navigate: PropTypes.shape({}).isRequired,
    subscriptionInfos: PropTypes.shape({}),
    syncSubscriptionInfos: PropTypes.func.isRequired,
};

Edit.defaultProps = {
    user: null,
};

export default userProvider(({ params: { id } }) => id)(Edit);
