import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import fullName, { afficheNomPrenom } from 'common/utils/fullName';
import SelectSignature from './SelectSignature';
import { useSession } from '../../../../../sessions';

const SelectSignatures = ({ rip, clientState, partnerState, partnerShouldSign, enLigne }) => {
    const [clientSignatureMode, setClientSignatureMode] = clientState;
    const [partnerSignatureMode, setPartnerSignatureMode] = partnerState;

    const session = useSession();

    const definiLeNomDuConseillerAAfficher = () => {
        if (!enLigne) {
            return afficheNomPrenom(_.get(session, 'firstName'), _.get(session, 'lastName'));
        }

        return afficheNomPrenom(_.get(rip, 'user.firstName'), _.get(rip, 'user.lastName'));
    };

    return (
        <div className="sign-configuration">
            <SelectSignature
                title={`Client (${fullName(rip)})`}
                selectedSignature={clientSignatureMode}
                onClick={setClientSignatureMode}
                enLigne={enLigne}
                avecAlerte
            />
            {partnerShouldSign && (
                <SelectSignature
                    title={`Conjoint (${fullName({ firstName: rip.partnerFirstName, lastName: rip.partnerLastName })})`}
                    selectedSignature={partnerSignatureMode}
                    onClick={setPartnerSignatureMode}
                    enLigne={enLigne}
                    avecAlerte
                />
            )}
            <SelectSignature
                title={`Conseiller (${definiLeNomDuConseillerAAfficher()})`}
                manualOnly
            />
        </div>
    );
};

SelectSignatures.propTypes = {
    rip: PropTypes.shape({
        partnerFirstName: PropTypes.string,
        partnerLastName: PropTypes.string,
    }).isRequired,
    clientState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    partnerState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    partnerShouldSign: PropTypes.bool,
    enLigne: PropTypes.bool,
};

export default SelectSignatures;
