import { get } from 'lodash/fp';
import validate, {
    branchValidate,
    checkRequiredField,
    checkRequiredIntegerField,
} from '../lib';

const isLifePlansRequiredAfterMEPDate = (createdDateRip) => {
    const MEPDate = new Date('2024-07-18T00:00:00.000Z');
    const RipCreatedDate = new Date(createdDateRip);

    return RipCreatedDate > MEPDate;
};

export default validate([
    checkRequiredField('financialCapacity'),
    branchValidate((errors, { createdAt }) => isLifePlansRequiredAfterMEPDate(createdAt), [
        checkRequiredField('lifePlans'),
    ]),
    branchValidate((errors, { familySituation, dualRip, createdAt }) => isLifePlansRequiredAfterMEPDate(createdAt) && ('cohabitation' === familySituation && dualRip), [
        checkRequiredField('partner.lifePlans'),
    ]),
    branchValidate((errors, { financialCapacity }) => financialCapacity, [
        checkRequiredIntegerField('financialMinimumSupply'),
        checkRequiredIntegerField('financialMaximumSupply'),
        checkRequiredIntegerField('projectMinimumSavings'),
        checkRequiredIntegerField('projectMaximumSavings'),
    ]),
    branchValidate((errors, { familySituation }) => 'cohabitation' === familySituation, [
        checkRequiredField('partner.hasFinancialCapacity'),
        branchValidate((errors, values) => get('partner.hasFinancialCapacity', values), [
            checkRequiredIntegerField('partner.financialSupply.minimum'),
            checkRequiredIntegerField('partner.financialSupply.maximum'),
            checkRequiredIntegerField('partner.projectSavings.minimum'),
            checkRequiredIntegerField('partner.projectSavings.maximum'),
        ]),
    ]),
]);
