import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/Icon';
import { useOnline } from 'components/withOnline';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import { GET } from '../../utils/httpMethods';
import TutorialCard from './TutorialCard';

import './ErrorPage.scss';

import cacheTutorial1 from './cache-tutorial-1.png';
import cacheTutorial2 from './cache-tutorial-2.png';
import cacheTutorial3 from './cache-tutorial-3.png';
import cacheTutorial4 from './cache-tutorial-4.png';

const Offline = () => (
    <p>
        L&apos;application rencontre actuellement une erreur, veuillez&nbsp;
        <span className="emphasize">ne pas</span> vous déconnecter,&nbsp;
        <span className="emphasize">ni</span> vider le cache pour éviter toute perte de données.
    </p>
);

const Online = () => (
    <Fragment>
        <p>
            L&apos;application rencontre actuellement une erreur.<br />
            Pour éviter tout problème de version, vous pouvez essayer de&nbsp;
            <span className="emphasize">vider le cache</span> :
        </p>
        <div className="tutorial">
            <TutorialCard
                image={cacheTutorial1}
                step="1"
                content={'Clique droit + "Inspecter"'}
            />
            <TutorialCard
                image={cacheTutorial2}
                step="2"
                content={'Onglet "Application"'}
            />
            <TutorialCard
                image={cacheTutorial3}
                step="3"
                content={'Cliquer sur  "Clear site data"'}
            />
            <TutorialCard
                image={cacheTutorial4}
                step="4"
                content="Rafraichir la page"
            />
        </div>
    </Fragment>
);

const ErrorPage = ({ resetErrors }) => {
    const cookies = new Cookies();
    const jeton = cookies.get('__jeton');

    const redirectUrl = jeton && decodeURI(jeton.split('¤')[1]);
    const useNavFromReact = useNavigate();

    const [emailError, setEmailError] = useState('');

    useEffect(() => {
        (async () => {
            let userData = localStorage.getItem('userData') || null;

            if (userData) {
                userData = JSON.parse(userData);
            } else {
                const response = await GET('/api/syncInstanceSettings');
                const data = await response.json();
                userData = data;
            }

            setEmailError(userData.instanceSettings.emailError);
        })();
    }, []);

    const backToHome = useCallback(() => {
        resetErrors();
        useNavFromReact('/');
    });

    const backToRip = useCallback(() => {
        resetErrors();
        useNavFromReact(redirectUrl);
    });

    const online = useOnline();

    return (
        <div className="error-page">
            <div>
                <div>
                    <Icon icon="exclamation-circle" />
                    <h1>Une erreur s&apos;est produite...</h1>
                </div>
                <div>
                    {
                        online
                            ? <Online />
                            : <Offline />
                    }
                    <p>Si le problème persiste, merci d&apos;adresser un mail à {emailError}.</p>
                </div>
                <button
                    onClick={redirectUrl ? () => backToRip() : () => backToHome()}
                    className="back-button"
                >
                    <Icon icon="arrow-circle-left" />
                    &nbsp; {redirectUrl ? 'Retour à votre RIP' : 'Retour aux RIPs'}
                </button>
            </div>
        </div>
    );
};

ErrorPage.propTypes = {
    resetErrors: PropTypes.func.isRequired,
};

export default ErrorPage;
