/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as userRegular } from '@fortawesome/free-regular-svg-icons';
import { faUser, faUsers, faExclamation, faBoxArchive, faWrench, faInfo, faShare, faRightFromBracket, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ReactSVG } from 'react-svg';
import { FaIcon } from './fa';

const Icon = ({ icon, path, ...props }) => {
    if ('userN' === icon) {
        return (<FontAwesomeIcon icon={faUser} {...props} />);
    }
    if ('user-regular' === icon) {
        return (<FontAwesomeIcon icon={userRegular} {...props} />);
    }
    if ('users' === icon) {
        return (<FontAwesomeIcon icon={faUsers} {...props} />);
    }
    if ('exclamation' === icon) {
        return (<FontAwesomeIcon icon={faExclamation} {...props} />);
    }
    if ('archive' === icon) {
        return (<FontAwesomeIcon icon={faBoxArchive} {...props} />);
    }
    if ('wrench' === icon) {
        return (<FontAwesomeIcon icon={faWrench} {...props} />);
    }
    if ('info' === icon) {
        return (<FontAwesomeIcon icon={faInfo} {...props} />);
    }
    if ('share' === icon) {
        return (<FontAwesomeIcon icon={faShare} {...props} />);
    }
    if ('sign-out' === icon) {
        return (<FontAwesomeIcon icon={faRightFromBracket} {...props} />);
    }
    if ('boolean-check' === icon) {
        return (<div className="icon" style={{ color: 'green' }}><FontAwesomeIcon icon={faCheck} className="boolean-check" /></div>);
    }
    if ('boolean-xmark' === icon) {
        return (<div className="icon" style={{ color: 'red', size: 'xs' }}><FontAwesomeIcon icon={faXmark} className="boolean-xmark" /></div>);
    }
    if (icon) {
        return <FaIcon icon={icon} {...props} />;
    }

    return <ReactSVG src={path} svgClassName="icon" {...props} />;
};

Icon.propTypes = {
    icon: PropTypes.string,
    path: PropTypes.string,
};

Icon.defaultProps = {
    icon: null,
    path: null,
};

export default Icon;
